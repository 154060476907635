<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.87868 7.87868C8.44129 7.31607 9.20435 7 10 7H11V6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6V7H16C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9H13V11H14C14.7957 11 15.5587 11.3161 16.1213 11.8787C16.6839 12.4413 17 13.2043 17 14C17 14.7957 16.6839 15.5587 16.1213 16.1213C15.5587 16.6839 14.7957 17 14 17H13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V17H8C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15H11V13H10C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7957 7 10C7 9.20435 7.31607 8.44129 7.87868 7.87868ZM11 11V9H10C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11H11ZM13 13V15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14C15 13.7348 14.8946 13.4804 14.7071 13.2929C14.5196 13.1054 14.2652 13 14 13H13Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
    />
  </svg>
</template>
